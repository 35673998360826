.footer-container {
    border-top: 1px solid #e5e5e5;
    padding: 40px 4% 40px 4%;  
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
    text-align: center;
    

    .footer {
        margin: 0;
        display: flex;
        justify-content: space-between;
        background-color: #f2f2f2;
        width: min(1200px, 100%);
        gap: 20px;


        .footer-links {
            flex: 1 auto;
            width: 100%;
            
            h1 {
                font-weight: bold;
                margin-bottom: 10px;
                font-size: 1.5rem;
            }
        
            p {
                display: block;
                margin-bottom: 5px;
                color: #333;
                text-decoration: none;
            }

            a {
                display: block;
                margin-bottom: 5px;
                color: #333;
                text-decoration: none;
            }
        
            a:hover {
                text-decoration: underline;
            }
        }    
    }
}

