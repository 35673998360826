.feature-showcase-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    width: min(1200px, 100%);
    overflow: hidden;

    .feature-showcase {
        margin: 20px;
        padding: 20px;
        background-color: #FAF9F6;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
        border-radius: 20px;
        // The card container
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap: 20px;
        transition: all 0.3s ease-out;
        height: 100%;

        .feature-showcase-content {
            // The three feature cards
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 20px;
            width: 100%;    
            height: 100%;
            padding: 20px 20px 20px 20px;
            transition: all 0.3s ease-out;

            &:hover {
                transform: scale(1.05);
            }

            .feature-showcase-image {
                // The background image of the title
                width: 100%;
                height: 170px;
                object-fit: cover;
                border-radius: 20px;
            }

            .feature-showcase-title {
                // The title text
                font-family: 'Fjalla One', sans-serif;
                font-size: 30px;
                color: #28231D;
                margin: 0;                
            }
            
        }

        .feature-showcase-divider {
            // The divider line between the cards
            border: 1px solid rgba(40, 35, 29, 0.5);
            height: 100px;
        }
    }
}

@media (max-width: 768px) {
    .feature-showcase-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        padding: 0;
        margin: 0;
        width: 100%;

        .feature-showcase {
            flex-direction: column;
            gap: 20px;
            padding: 20px 0;
            margin: 0;
            border-radius: 0px;

            .feature-showcase-content {
                flex-direction: column;
                gap: 20px;
                padding: 0;
                margin: 0;
                width: 100%;

                .feature-showcase-image {
                    width: 80%;
                    height: 200px;
                    object-fit: cover;
                    border-radius: 20px;
                    padding: 0;
                    margin: 0;
                }

                .feature-showcase-title {
                    font-size: 25px;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    font-family: 'Fjalla One', sans-serif;
                    color: #28231D;
                }

                p {
                    font-size: 20px;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    font-family: 'Fjalla One', sans-serif;
                    color: #28231D;
                }

                &:hover {
                    transform: none;
                }

                &:after {
                    content: '';
                    display: block;
                    width: 50%;
                    height: 1px;
                    background-color: rgba(40, 35, 29, 0.5);                    
                }

                &:last-child:after {
                    display: none;
                }
            }
        }

        .feature-showcase-divider {
            display: none;
        }
    }
}