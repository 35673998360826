@font-face {
    font-family: 'Source Serif Pro';
    src: url('./fonts/SourceSerifPro-Black.otf') format('truetype');
}
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Fjalla+One&family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap');

main {
    margin: 0;
    padding: 0;
    width: 100%;
}

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #FAF9F6;
}

.MuiTypography-root {
    font-family: 'Source Serif Pro', serif;
    /* font-weight: 400; */
}