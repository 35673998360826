.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 1rem;

  .countdown-text {
    display: flex;
    justify-content: center;
    // align-items: center;
    flex-direction: row;
    font-size: 1.5rem;
    font-weight: normal;
    color: #333;
    margin-bottom: 1rem;
    gap: 1rem;

    .days, .hours, .minutes, .seconds {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 50px;
      font-weight: normal;
      color: #333;
      margin: 0px 1rem 0 1rem;
      width: 90px;
      height: 90px;
      background-color: #f4f4f4;
      border-radius: 0.5rem;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    }

    .countdown-separator {
      font-size: 40px;
      font-weight: normal;
      color: #333;
      margin: 15px 0 0 0;
    }
  }
}

.countdown-header {
  font-family: 'Fjalla One', sans-serif;
  font-size: 40px;
  font-weight: normal;
  width: 100%;
}

@media (max-width: 768px) {
  .countdown {
    all: unset;
    padding: 0;
    margin: 0;

    .countdown-text {
      font-size: 1rem;
      font-weight: normal;
      margin-bottom: 0.5rem;
      gap: 0.5rem;

      .days, .hours, .minutes, .seconds {
        font-size: 30px;
        font-weight: normal;
        margin: 0px 0.5rem 0 0.5rem;
        width: 60px;
        height: 60px;
      }

      .countdown-separator {
        font-size: 20px;
        font-weight: normal;
        margin: 10px 0 0 0;
      }
    }
  }

  .countdown-header {
    font-family: 'Fjalla One', sans-serif;
    font-size: 30px;
    font-weight: normal;
    width: 100%;
  }
}