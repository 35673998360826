.glowing-text {
    font-family: 'Fjalla One', sans-serif;
    font-size: 40px;
    font-weight: bold;
    color: #28231D;
    background-color: #FFEFCF;
    box-shadow: rgb(0, 0, 0, 0.3) 0 5px 10px;
    border-radius: 20px;
    padding: 15px 30px;
    transition: all 0.5s;

    &:hover {
        animation: none;
        text-shadow: none;
        color: #FFD700;
        transform: scale(1.1);
        box-shadow: rgb(0, 0, 0, 0.5) 0 5px 20px;
        background-color: #28231D;
    }
}

.explosion {
    margin-left: 50%;
    margin-top: 0;
}

.custom-shape-divider-bottom-1714464732 {
    position: absolute;
    top: 308px;

    // Have it centred in the middle of the page
    left: calc(50% - 226px);
    transform: translateX(-50%);

    width: 599px;
    overflow: hidden;
    line-height: 0;
    transform: rotate(270deg);

    @media (max-width: 768px) {
        // Hide the shape on mobile
        display: none;
    }
}

.custom-shape-divider-bottom-1714464732 svg {
    position: relative;
    display: block;
    width: calc(155% + 1.3px);
    height: 147px;
    z-index: 1;
}

.custom-shape-divider-bottom-1714464732 .shape-fill {
    fill: #fffbee;
}

.hero-container {
    height: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #FAF9F6;
    background-image:-webkit-linear-gradient(315deg, #FAF9F6 0%, #fffbee 74%);

    .hero-left {
        color: #28231D;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-shadow: none;
        width: 50%;
        height: 100%;    

        .hero-links {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            gap: 20px;
            
            a {
                font-family: 'Fjalla One', sans-serif;
                font-size: 40px;
                font-weight: bold;
                color: #28231D;
                border-radius: 20px;
                transition: all 0.5s;

                &:hover {
                    animation: none;
                    text-shadow: none;
                    color: #FFD700;
                    transform: scale(1.2);
                    rotate: 5deg; 
                    filter: drop-shadow(5px 5px 2px rgba(255, 239, 207, 1));
                }
            }
        }        
        
        h1 {
            font-family: 'Fjalla One', sans-serif;
            font-size: 50px;
            font-weight: normal;
            margin: 0;
            padding: 0 20px;
        }

        p {
            width: 100%;
            line-height: 0.6;
            font-family: 'Fjalla One', sans-serif;
            font-size: 28px;
            font-weight: 100;
            margin-bottom: 0px;
        }

        h2 {
            font-family: 'Fjalla One', sans-serif;
            font-size: 48px;
            font-weight: 100;
            margin-top: 60px;
        }
    }

    .hero-right {
        background-image: url('../../assets/fridgefindshero.jpg');
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 100%;
    }
}

@media (max-width: 768px) {
    .hero-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding: 0;
        margin: 0;
        
        .hero-left {
            padding: 0;
            margin: 0;
            width: 100%;

            h1 {
                font-size: 30px;
                padding: 0;
                margin: 0;
            }

            p {
                font-size: 20px;
                line-height: 1.1;
            }

            h2 {
                font-size: 30px;
                margin: 40px 0 20px 0;
            }

            .hero-links {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;

                a {
                    font-size: 30px;
                }
            }
        }

        .hero-right {
            display: none;
        }
    }
}