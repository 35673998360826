.home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // gap: 20px;
    // padding: 20px;
    background-color: #FFEFCF;
    color: #333;
    font-family: 'Fjalla One', sans-serif;
    font-size: 20px;
    font-weight: 100;
    text-align: center;
    // text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    width: 100%;
    margin: 0;
    padding: 0;

    .home-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        h1 {
            font-family: 'Fjalla One', sans-serif;
            font-size: 40px;
            font-weight: normal;
            margin: 30px 0 0 0;
            width: 100%;
        }

        p {
            width: 100%;
            line-height: 1.5;
            font-family: 'Fjalla One', sans-serif;
            font-size: 20px;
            font-weight: 100;
            margin: 0;
        }
    }

    .home {
        border-top: 2px solid #FFD6B6;
    }
}

@media (max-width: 768px) {
    .home-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #FFEFCF;
        color: #333;
        font-family: 'Fjalla One', sans-serif;
        font-size: 20px;
        font-weight: 100;
        text-align: center;
        width: auto;
        margin: 0;
        padding: 0;

        .home-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            h1 {
                font-family: 'Fjalla One', sans-serif;
                font-size: 30px;
                font-weight: normal;
                width: 100%;
            }

            p {
                width: 80%;
                line-height: 1.5;
                font-family: 'Fjalla One', sans-serif;
                font-size: 20px;
                font-weight: 100;
                margin: 0 0 20px 0;
            }
        }
         
        .home {
            border-top: 2px solid #FFD6B6;
        }
    }
}