.navbar {
    background-color: #FFEFCF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    border-bottom: 2px solid #FFD6B6;
    width: auto;
    color: #28231D; // Charcoal

    .navbar-logo {
        font-size: 1.5rem;
        font-weight: bold;
        text-decoration: none;
        color: #fff;
        margin-left: 40px;
        width: 40px;
        height: 40px;
        border-radius: 10px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        filter: drop-shadow(2px 2px 2px rgb(0, 0, 0, 0.1));

        img {
            width: 60px;
            height: 60px;
            filter: brightness(0);
        }
        
        &:hover {
            cursor: pointer;
        }
    }

    .navbar-title {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-family: 'Source Serif Pro', serif;
        font-weight: 900;
        font-size: 30px;
        color: #28231D;    
        cursor: pointer;
        text-decoration: none;
        text-shadow: 3px 3px 0px rgba(0, 0, 0, 0.2);
        padding: 0;
        margin: 0;

        h1 {
            margin: 0;
            padding: 0;
        }
    }

    .navbar-links {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;

        a {
            text-decoration: none;
            font-size: 1.2rem;
            margin: 0 10px;
            color: #28231D; 
            cursor: not-allowed; // pointer;
        }

        a:hover {
            text-decoration: underline;
        }

        .navbar-sign-up {
            background-color: #FFA500;
            color: #fff;
            padding: 10px 20px;
            border-radius: 10px;
            text-decoration: none;
            margin-right: 20px;
            transition: all 0.3s ease-out;

            &:hover {
                filter: brightness(1.1);
                transform: scale(1.05);
                text-decoration: none;
            }

            &:active {
                transform: scale(0.95);
            }
        }
    }

    .navbar-links-mobile {
        display: none;
    }
}

@media (max-width: 768px) {
    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        height: auto;
        padding: 0;
        margin: 0;
        
        .navbar-logo {
            margin: 0 0 0 20px;
            padding: 0;
            filter: brightness(0);
    
            img {
                width: 40px;
                height: 40px;
            }
        }
    
        .navbar-title {
            position: static;
            transform: none;
            left: 0;
            margin: 0;
            padding: 0;
            font-size: 20px;
            font-weight: bold;
            color: #28231D;
            cursor: pointer;
            font-family: 'Source Serif Pro', serif;
            text-shadow: 3px 3px 0px rgba(0, 0, 0, 0.2);
        }
    
        .navbar-links {
            display: none;
        }
    
        .navbar-links-mobile {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // width: 100%;
            // height: 100%;
            margin: 0px 20px 0 0;
            font-size: 32px;
            color: #28231D;
    
            .navbar-links-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 32px;
                color: #28231D;
                cursor: pointer;
            }
        
        }
    }
}

    